<template>
	<div class="container">
		<div class="row">
			<div class="col">
				<h1>Profile</h1>
				<div class="edit-form">
					<Form @submit="insertItem" :validation-schema="schema">
						<div class="form-floating mb-3">
							<Field name="title" type="text" id="title" class="form-control" v-model="currentItem.title" />
							<label for="title">Title</label>
							<ErrorMessage name="title" class="error-feedback d-block alert alert-warning" />
						</div>

						<div class="form-floating mb-3">
							<Field
								name="date"
								type="date"
								id="date"
								class="form-control"
								:value="this.$filters.formatDate(this.currentItem.date)"
								@input="this.currentItem.date = $event.target.value"
							/>
							<label for="date">Date</label>
							<ErrorMessage name="date" class="error-feedback d-block alert alert-warning" />
						</div>

						<div class="mb-3 d-flex">
							<button class="btn btn-primary me-auto" :validation-schema="schema" :disabled="insertProgess">
								<span v-show="insertProgess" class="spinner-border spinner-border-sm me-3" role="status" aria-hidden="true"></span>
								<span>Insert</span>
							</button>
							<router-link class="btn btn-primary" to="/items">Back</router-link>
						</div>
					</Form>
				</div>
			</div>
		</div>
	</div>

	<div class="d-block mt-5 pt-5"></div>
</template>

<script>
import ItemService from '../services/item.service';
import {Form, Field, ErrorMessage} from 'vee-validate';
import * as yup from 'yup';

export default {
	name: 'AppProfile',
	components: {
		Form,
		Field,
		ErrorMessage,
	},
	data() {
		const schema = yup.object().shape({
			title: yup.string().min(3, 'Please enter more than 3 character').required('Title is required!'),
		});

		return {
			currentUserId: null,
			currentItem: {
				title: '',
				date: this.$filters.todayDate(),
			},
			insertProgess: false,
			schema,
		};
	},
	methods: {
		insertItem(item) {
			this.insertProgess = true;
			ItemService.insert(item)
				.then((response) => {
					// Check if success, if not show error message
					if (!response.data.error) {
						this.$router.push('/items');
					} else {
						this.insertProgess = false;
						this.$toast.info(response.data.error.message);
					}
				})
				.catch((e) => {
					this.catchError(e);
				});
		},
		catchError(e) {
			// Check if not authorized, if not show error message
			if (e.response.status === 401) {
				this.$store.dispatch('auth/logout');
				this.$router.push('/login');
			} else {
				this.insertProgess = false;
				console.log(e);
			}
		},
	},
	created() {
		this.currentUserId = this.$store.state.auth.user.id;
	},
	mounted() {},
};
</script>
