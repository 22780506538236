import axios from 'axios';
import authHeader from './auth.header';

const API_URL = process.env.VUE_APP_API_URL;

class ItemService {
	getAll() {
		return axios.get(API_URL + 'items', {headers: authHeader()});
	}

	get(id) {
		return axios.get(API_URL + `item/${id}`, {headers: authHeader()});
	}

	insert(data) {
		return axios.post(API_URL + `item`, data, {headers: authHeader()});
	}

	update(id, data) {
		return axios.put(API_URL + `item/${id}`, data, {headers: authHeader()});
	}

	delete(id) {
		return axios.delete(API_URL + `item/${id}`, {headers: authHeader()});
	}

	search(searchword) {
		return axios.get(API_URL + `items?searchword=${searchword}`, {headers: authHeader()});
	}
}

export default new ItemService();
